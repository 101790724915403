<template>
   <div>
      <Notification type="success" message="Bienvenido" ref="welcome_notification" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <Login />
   </div>
</template>

<script>
import Login from "../components/Login/Login.vue";

export default {
   components: {
      Login,
   },
   mounted: async function () {
      if (this.$router.currentRoute.query.status == "denied") {
         this.$refs.denied_notification.Show();
      }
      if (this.$router.currentRoute.query.status == "welcome") {
         this.$refs.welcome_notification.Show();
      }
   },
   beforeRouteUpdate(to, from, next) {
      if (to.query.status == "denied") {
         this.$refs.denied_notification.Show();
      }
      if (to.query.status == "welcome") {
         this.$refs.welcome_notification.Show();
      }
      next();
   },
};
</script>
